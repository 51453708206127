
export default function WaterDrop({ ...props }) {
  return (
    <svg {...props} viewBox='0 0 31 37' fill='none'>
      <path
        d='M25.9972 25.9979L16.2825 35.7126C15.7569 36.2382 14.906 36.2371 14.3816 35.7126L4.66682 25.9979C-1.22376 20.1073 -1.22488 10.5569 4.6657 4.66633C10.5563 -1.22425 20.1078 -1.22425 25.9984 4.66633C31.8889 10.5569 31.8889 20.1084 25.9984 25.999L25.9972 25.9979Z'
        fill='white'
      />
    </svg>
  )
}
