import React from 'react'
import styled from 'styled-components'

export const LogoWrapper = styled.div`
  background-size: 100% auto;
  display: block;
`

export default ({ ...props }) => {
  return (
    <LogoWrapper>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 190 28'
        fill='none'
        color={'white'}
        {...props}>
        <g clipPath='url(#clip0_434_5190)'>
          <path
            d='M74.5261 12.4851C70.6834 12.4851 68.3301 14.8043 68.3301 19.6681V27.2H62.5469V6.81703H68.3301V10.9234C69.5089 8.11065 71.4111 6.28511 74.8325 6.43831V12.4851H74.5261Z'
            fill='currentColor'
          />
          <path
            d='M96.4259 19.0213H82.2423C82.8168 21.6468 84.6381 23.0128 87.2255 23.0128C89.166 23.0128 90.5661 22.4043 92.1661 20.9234L95.4812 23.8511C93.579 26.2085 90.8384 27.6553 87.1489 27.6553C81.0252 27.6553 76.5059 23.3575 76.5059 17.1192V17.0426C76.5059 11.2213 80.6465 6.43405 86.5829 6.43405C93.3917 6.43405 96.5068 11.7192 96.5068 17.4979V17.5745C96.5068 18.1447 96.4685 18.4894 96.4259 19.0213ZM86.5829 11.0723C84.1871 11.0723 82.6253 12.783 82.1742 15.4085H90.8767C90.5363 12.8213 89.0171 11.0723 86.5829 11.0723Z'
            fill='currentColor'
          />
          <path
            d='M124.528 27.2V15.8298C124.528 13.0894 123.315 11.6851 121.141 11.6851C118.966 11.6851 117.647 13.0894 117.647 15.8298V27.2H111.864V15.8298C111.864 13.0894 110.651 11.6851 108.476 11.6851C106.302 11.6851 104.982 13.0894 104.982 15.8298V27.2H99.1992V6.81702H104.982V9.70638C106.31 7.99574 108.063 6.43829 110.991 6.43829C113.651 6.43829 115.668 7.61702 116.732 9.67234C118.519 7.57872 120.647 6.43829 123.422 6.43829C127.724 6.43829 130.307 9.02553 130.307 13.9277V27.2H124.524H124.528Z'
            fill='currentColor'
          />
          <path
            d='M143.722 27.6553C137.407 27.6553 132.738 22.9787 132.738 17.1192V17.0426C132.738 11.1872 137.453 6.43405 143.803 6.43405C150.152 6.43405 154.795 11.1106 154.795 16.9702V17.0468C154.795 22.9021 150.071 27.6553 143.722 27.6553ZM149.084 17.0468C149.084 14.0426 146.918 11.417 143.722 11.417C140.526 11.417 138.441 13.966 138.441 16.9702V17.0468C138.441 20.0511 140.607 22.6723 143.803 22.6723C146.998 22.6723 149.084 20.1277 149.084 17.1234V17.0468Z'
            fill='currentColor'
          />
          <path
            d='M164.259 27.5404C160.723 27.5404 158.323 26.1362 158.323 21.4213V11.7617H155.889V6.81703H158.323V1.60852H164.106V6.81703H168.894V11.7617H164.106V20.4681C164.106 21.7958 164.672 22.4426 165.966 22.4426C167.034 22.4426 167.987 22.1787 168.821 21.7234V26.3617C167.6 27.0851 166.2 27.5404 164.259 27.5404Z'
            fill='currentColor'
          />
          <path
            d='M189.92 19.0213H175.736C176.311 21.6468 178.132 23.0128 180.72 23.0128C182.66 23.0128 184.06 22.4043 185.66 20.9234L188.975 23.8511C187.073 26.2085 184.333 27.6553 180.643 27.6553C174.519 27.6553 170 23.3575 170 17.1192V17.0426C170 11.2213 174.141 6.43405 180.077 6.43405C186.886 6.43405 190.001 11.7192 190.001 17.4979V17.5745C190.001 18.1447 189.963 18.4894 189.92 19.0213ZM180.077 11.0723C177.681 11.0723 176.119 12.783 175.668 15.4085H184.371C184.03 12.8213 182.511 11.0723 180.077 11.0723Z'
            fill='currentColor'
          />
          <path
            d='M20.1669 13.9319V13.8426C20.1669 9.74043 21.6988 6.00426 24.2692 3.14894V0.531921H16.8774V10.5106H7.39182V0.531921H0V27.2H7.39182V17.0681H16.8774V27.2H24.2692V24.634C21.6946 21.7957 20.1669 18.0596 20.1669 13.9362V13.9319Z'
            fill='currentColor'
          />
          <path
            d='M45.0565 25.3702C42.7713 26.8553 39.9882 27.7319 36.9412 27.7319C28.7111 27.7319 22.6172 21.5957 22.6172 13.9404V13.8638C22.6129 6.20851 28.7877 0 37.0136 0C45.2395 0 51.3376 6.13192 51.3376 13.7872V13.8638C51.3376 16.6851 50.4993 19.3489 48.9758 21.5234L51.7206 23.8468L47.9843 28L45.0522 25.3702H45.0565ZM39.7243 20.6851L36.0263 17.5234L39.7626 13.3362L43.3841 16.6128C43.6905 15.8128 43.8394 14.9404 43.8394 14.0255V13.9489C43.8394 10.0638 41.0606 6.67234 36.9412 6.67234C32.8219 6.67234 30.2005 10.0255 30.2005 13.8723V13.9489C30.2005 17.7957 32.9453 21.1872 37.0221 21.1872C38.0136 21.1872 38.9286 20.9957 39.7286 20.6936L39.7243 20.6851Z'
            fill='currentColor'
          />
        </g>
        <defs>
          <clipPath id='clip0_434_5190'>
            <rect width='190' height='28' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </LogoWrapper>
  )
}
