import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import {
  FullHeightContainer,
  LandingBranding,
  SignUpContainer,
  SignUpInner,
  Snackbar
} from '../modules'
import { Invite } from '../components/forms'
import { actions as sessionActions } from '../reducers/session'
import { activate, sendInvitations } from '../services/api'
import { setAuthData } from '../services/auth'
import { RouteComponentProps } from 'react-router-dom'
import { DefaultError, ErrorI } from '../interfaces/error'
import FullLogo from '../components/icons/FullLogo'

const Activate: React.FC<RouteComponentProps> = (props) => {
  const title = 'Welcome to HQ Remote!'
  const [teamId, setTeamId] = useState<number | null>(null)
  const [errors, setErrors] = useState<ErrorI>(DefaultError)
  const [success, setSuccess] = useState<ErrorI>(DefaultError)
  const [onSend, setOnSend] = useState<boolean>(false)
  const [data, setData] = useState<{ invitationsInputs: string[] }>({
    invitationsInputs: ['']
  })
  const setError = (msg: string, error = true) => {
    setErrors({
      status: error,
      message: msg
    })
    setOnSend(false)
  }
  const updateForm = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const dataCopy = { ...data }
    dataCopy.invitationsInputs[index] = value
    setData(data)
    setError('', false)
  }
  const populateEmailInput = () => {
    const dataCopy = { ...data }
    dataCopy.invitationsInputs.push('')
    setData(dataCopy)
  }
  const removeEmailInput = (index: number) => {
    const dataCopy = { ...data }
    dataCopy.invitationsInputs.splice(index, 1)
    setData(dataCopy)
  }
  const skip = () => {
    props.history.push('/')
  }
  const download = () => {
    open(`${process.env.REACT_APP_SITE_URL}/download`)
  }
  const submitInvite = () => {
    let invitations = data.invitationsInputs.filter((input) => {
      const email = input.trim()
      return email !== null && email !== ''
    })

    if (invitations.length === 0) {
      setError('You must provide at least 1 email.')
      return
    }

    if (!invitations.every(email => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))) {
      setError('Not a valid email format.')
      return
    }

    setOnSend(true)
    sendInvitations(Number(teamId), {
      emails: invitations
    }).then(() => {
      setData({
        invitationsInputs: ['']
      })
      setSuccess({
        status: true,
        message: 'Invitation(s) sent!'
      })
      setOnSend(false)
    }).catch(() => {
      setError(
        'We\'re sorry, we\'re unable to submit your request. Please contact our administrator.'
      )
    })
  }
  const dispatch = useDispatch()

  useEffect(() => {
    const { location } = props
    const parsed = queryString.parse(location.search)

    if (!parsed.activation_code) {
      props.history.push('/not-found')
    } else {
      document.title = title
      activate(String(parsed.activation_code)).then((res) => {
        setTeamId(res.data.user.selectedTeam)
        setAuthData(res.data.user)
        dispatch(sessionActions.loginSuccess())
      }).catch(() => {
        props.history.push('/not-found')
      })
    }
  }, [])

  return (
    <FullHeightContainer>
      <div className='flash-section'>
        <SignUpContainer>
          <SignUpInner>
            <LandingBranding>
              <FullLogo />
            </LandingBranding>
            <Invite
              data={data.invitationsInputs}
              populate={() => populateEmailInput()}
              remove={removeEmailInput}
              change={updateForm}
              skip={() => skip()}
              loading={onSend}
              submit={() => submitInvite()}
              disabled={errors.status || data.invitationsInputs?.length <= 0}
              download={() => download()}
            />
          </SignUpInner>
        </SignUpContainer>
      </div>
      <Snackbar type='error' show={errors.status} message={errors.message} zIndex={9999} />
      <Snackbar
        type='success'
        show={success.status}
        message={success.message}
        zIndex={9999}
      />
    </FullHeightContainer>
  )
}

export default Activate
