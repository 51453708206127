import styled, { css } from 'styled-components'
import { GeneralComponentProps } from '../interfaces/component'

export const SignUpContainer = styled.div`
  min-height: 100%;
  position: relative;
  width: 100%;
`

export const SignUpInner = styled.div`
  margin: 36px;
  position: relative;
`

export const SignUpFormContainer = styled.div`
  margin-top: 100px;

    @media only screen and (max-width: 390px){
    margin-top: 88px;
  }
`

export const SignUpForm = styled.div`
  margin: 30px auto;
  max-width: 696px;
  transition: all 0.25s ease;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  
  @media only screen and (max-width: 768px){
    width: 100%;
  }

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: 'white';
    width:100%;
    margin-bottom: 23px;
    
    @media only screen and (max-width: 500px){
      font-size: 20px;
      margin-bottom: 18px;
    }
  }
    
  p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 23px;
    margin-top: -9px;
    
    @media only screen and (max-width: 500px){
      font-size: 14px;
      margin-bottom: 18px;
      margin-top: -8px;
    }
  }
`

export const SignUpFormGroup = styled.div`
  max-width: initial;
`

export const SignUpFormRow = styled.div`
  margin: 0 0 20px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: table;
  }
`

export const SignUpFormRowHalf = styled.div`

  @media only screen and (min-width: 500px) {
    display: inline-block;
    margin-bottom: 0;
    width: calc(50% - 12px) !important;

    &:first-of-type {
      margin-right: 24px;
    }
  }
`

export const PaginationContainer = styled.nav`
  bottom: 0;
  display: block;
  left: 0;
  padding: 20px 0;
  position: absolute;
  text-align: center;
  width: 100%;
  
  @media only screen and (min-width: 768px) {
    bottom: 40px;
    left: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
  }

  @media only screen and (min-height: 769px) {
    bottom: 70px;
  }
`

export const PaginationItem = styled.button < GeneralComponentProps > `
  border-color: transparent;  
  cursor: pointer;
  display: inline-block;
  height: 29px;
  font-size: 16px;
  line-height: 26px;
  width: 29px;
  font-weight: 700;
  margin-inline: 4px;
  background-color: transparent;

  p {
    position:relative;
    top: -38px;
    left: 7px;
  }
  ${(props) => {
    let style = css``

    if (props.current) {
      style = css`
        ${style}
        color: #3264FF;
      `
    }

    if (!props.current) {
      style = css`
      color: #FAFAFA;
      svg {
        path {
          fill:none;
        }
      }
      `
    }

    return style
  }}
`
export const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: baseline;
  padding-top: 13px;
  gap:8px;
`
