import React, { useEffect, useState } from 'react'
import {
  FullHeightContainer,
  LandingBranding,
  PaginationContainer,
  PaginationItem,
  SignUpContainer,
  SignUpInner,
  Snackbar
} from '../modules'
import {
  Email,
  Name,
  Password,
  Review,
  TeamName,
  TeamUrl
} from '../components/forms'
import { register } from '../services/api'
import { timezone as defaultTimezone } from '../utils'
import { SignUpFormData, SignUpFormDataSubmit } from '../components/forms/interface'
import FullLogo from '../components/icons/FullLogo'

const SignUp: React.FC = () => {
  const title = 'Create a team!'
  const [data, setData] = useState<SignUpFormData & SignUpFormDataSubmit>({
    email: '',
    teamName: '',
    teamUrl: '',
    firstName: '',
    lastName: '',
    password: '',
    timezone: defaultTimezone,
    tnc: false,
    displayName: '',
    name: '',
    passwordConfirmation: ''
  })
  const [errors, setErrors] = useState<{
    status: boolean;
    message: string;
  }>({
    status: false,
    message: ''
  })
  const [onSend, setOnSend] = useState<boolean>(false)
  const [success, setSuccess] = useState<{
    status: boolean;
    message: string;
  }>({
    status: false,
    message: 'Please check your email to verify your account.'
  })
  const setError = (msg: string, error = true) => {
    setOnSend(false)
    setErrors({
      status: error,
      message: msg
    })
  }
  const updateForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataCopy = { ...data }
    // @ts-ignore
    dataCopy[e.target.name] = e.target.value
    setData({
      ...data,
      ...dataCopy
    })
    if (!e.target.value) {
      setError('Field cannot be empty!')
    } else if (
      e.target.name === 'email' &&
      !e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      setError('Not a valid email format.')
    } else if (
      e.target.name === 'teamUrl' &&
      e.target.value.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)
    ) {
      setError('URL must not contain space or unique characters.')
    } else {
      setError('', false)
    }
  }

  const submitForm = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setOnSend(true)
    if (!data.tnc) {
      setError('You must accept the terms of service.')
      return
    }
    const dataCopy = { ...data }
    dataCopy.name = `${data.firstName}${data.lastName ?? ` ${data.lastName}`}`
    dataCopy.displayName = data.name
    data.passwordConfirmation = data.password

    register(data).then(() => {
      success.status = true

      setError('', false)
      setSuccess(success)
    }).catch(err => {
      let errMessage =
        'We\'re sorry, we\'re unable to submit your request. Please contact our administrator.'
      let caughtErrors = err.response.data.errors

      if (Object.keys(caughtErrors).length !== 0 && caughtErrors.constructor === Object) {
        errMessage = ''
        caughtErrors = Object.values(caughtErrors)
        caughtErrors.forEach((error: string[]) => {
          errMessage = errMessage + error.join('<br>')
          if (error.length === 1) {
            errMessage = errMessage + '<br>'
          }
        })
      }
      setError(errMessage)
    })
  }
  useEffect(() => {
    document.title = title
  }, [])
  const [step, setStep] = useState<number>(1)
  return (
    <FullHeightContainer>
      <div className='flash-section'>
        <SignUpContainer>
          <SignUpInner>
            <LandingBranding>
              <FullLogo />
            </LandingBranding>
            <Email
              currentStep={step}
              change={(e) => updateForm(e)}
              next={() => setStep(2)}
              data={data.email}
              formStep={1}
            />
            <TeamName
              currentStep={step}
              change={(e) => updateForm(e)}
              next={() => setStep(3)}
              data={data.teamName}
              formStep={2}
            />
            <TeamUrl
              currentStep={step}
              change={(e) => updateForm(e)}
              next={() => setStep(4)}
              data={data.teamUrl}
              formStep={3}
            />
            <Name
              currentStep={step}
              change={(e) => updateForm(e)}
              next={() => setStep(5)}
              data={{
                firstName: data.firstName,
                lastName: data.lastName
              }}
              formStep={4}
            />
            <Password
              currentStep={step}
              change={(e) => updateForm(e)}
              next={() => setStep(6)}
              data={data.password}
              formStep={5}
            />
            <Review
              currentStep={step}
              change={(e) => updateForm(e)}
              data={data}
              formStep={6}
              loading={onSend}
              submit={(e) => submitForm(e)}
            />
          </SignUpInner>
          {step < 6 && (
            <PaginationContainer>
              {[1, 2, 3, 4, 5].map((i) => {
                return (
                  <PaginationItem
                    key={i}
                    current={step === i}
                    disabled={step < i}
                    onClick={() => setStep(i)}>
                    {i}
                  </PaginationItem>
                )
              })}
            </PaginationContainer>
          )}
        </SignUpContainer>
        <Snackbar type='error' show={errors.status} message={errors.message} zIndex={9999} />
        <Snackbar
          type='success'
          show={success.status}
          message={success.message}
          zIndex={9999}
        />
      </div>
    </FullHeightContainer>
  )
}

export default SignUp
