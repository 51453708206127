import React from 'react'
import {
  TransparentButton,
  InputForm,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow,
  FormLabel
} from '../../modules'
import { FormFieldProps } from './interface'

interface FormEmailProps extends FormFieldProps {
  data: string;
}

const Email: React.FC<FormEmailProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>What's your Email Address?</h1>
        {props.formStep === 1 && (
          <p>
            Use HQ Remote Pro free for 7 days, without having to enter any card
            details!
          </p>
        )}
        <SignUpFormGroup>
          <SignUpFormRow>
            <FormLabel>Email Address</FormLabel>
            <InputForm
              type='email'
              placeholder='Email address'
              name='email'
              onChange={props.change}
              value={props.data}
            />
          </SignUpFormRow>
          <SignUpFormRow>
            <TransparentButton
              type='button'
              onClick={props.next}
              disabled={
                props.data === '' ||
                typeof props.data === 'undefined' ||
                !props.data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
              }>
              Next
            </TransparentButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default Email
