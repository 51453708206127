import React, { FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Logo } from './icons'
import { actions as sessionActions, getErrorMessage, getHasError } from '../reducers/session'
import { InputForm, FormLabel, LandingPage, Snackbar } from '../modules'
import { emailValidation } from '../utils'
import { RootState } from '../store'
import LoginSlackIcon from '../assets/img/login_slack.svg'
import LoginGoogleIcon from '../assets/img/login_google.svg'

const Header = styled.div`
  margin: 0;
`

type ErrorMessage = {
  status: boolean;
  message: string;
}

const LoginForm = () => {
  const session = useSelector((state: RootState) => state.session)
  const [errors, setErrors] = useState<ErrorMessage>({
    status: false,
    message: ''
  })
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const hasError = useSelector((state: RootState) => getHasError(state))
  const errMessage = useSelector((state: RootState) => getErrorMessage(state))
  const setError = (error: boolean, message = '') => {
    setErrors(error ? {
      status: true,
      message: message
    } : {
      status: false,
      message: ''
    })
  }

  const makeValidation = () => {
    if (!email || !password) {
      throw new Error('empty')
    }
    if (email.trim() === '' || password === '') {
      throw new Error('empty')
    }
    if (!emailValidation(email)) {
      throw new Error('invalidEmail')
    }
  }

  const dispatch = useDispatch()
  const login = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      makeValidation()
      setError(false)

      dispatch(sessionActions.login(email, password, true))
    } catch (err) {
      let message = 'Unable to submit your request, please try again.'

      // @ts-ignore
      if (err.message === 'empty') {
        message = 'Email and password are required.'
      }
      // @ts-ignore
      if (err.message === 'invalidEmail') {
        message = 'Invalid email format.'
      }
      setError(true, message)
    }
  }

  useEffect(() =>{
    if (hasError) {
      setError(true, errMessage)
    } else {
      setError(false)
    }
  }, [hasError])
  const inputStyles = {
    border: '2px solid #E5E5E5',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    paddingLeft: '18px',
    height: '48px',
    color: '#9D9D9D',
    fontSize: '13px',
    fontWeight: 500
  }
  const loggingIn = session.isAuthenticating || session.isAuthenticated
  const canLogin = email.trim() !== '' && password.trim() !== ''
  return (<>
    <LandingPage
      style={{
        backgroundColor: '#fff',
        color: '#000',
        width: '357px',
        height: '548px',
        borderRadius: '10px',
        padding: '25px 25px 0px 25px'
      }}
      className='container login-page p-relative'
    >
      <Header>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end'
        }}>
          <Logo />
        </div>
        <p style={{
          color: '#666666',
          fontWeight: 500,
          fontSize: '32px',
          marginBottom: '14px'
        }}>
          Log In
        </p>
      </Header>
      <div className='form-contain'>
        <form
          onSubmit={login}
          className='forms'
        >
          <div className='login-page-input'>
            <FormLabel className='login-page-label'>Email Address</FormLabel>
            <InputForm
              style={inputStyles}
              type='email'
              placeholder='Enter your email'
              name='email'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className='login-page-input p-relative'>
            <FormLabel className='login-page-label'>Password</FormLabel>
            <InputForm
              style={inputStyles}
              type='password'
              placeholder='Enter your password'
              name='password'
              onChange={(e) => setPassword(e.target.value)}
              className='form__text-input'
              value={password}
            />
          </div>
          <div className='form__row' style={{
            marginBottom: '0'
          }}>
            <Link to='/forgot-password' className='link-forgot-password'>
              Forgot password?
            </Link>
          </div>
          <div className='form__action login-page-input' style={{
            marginTop: '12px !important'
          }}>
            <input
              style={{
                color: '#fff',
                fontSize: '14px',
                fontWeight: 600,
                height: '53px',
                width: '100%',
                borderRadius: '6px',
                backgroundColor:
                  loggingIn || !canLogin ? '#3264FF80' : '#3264FF'
              }}
              type='submit'
              name='submit'
              disabled={loggingIn || !canLogin}
              value={
                loggingIn ? 'Logging in...' : 'Log In'
              }
              className='btn'
            />
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              width: '100%',
              color: '#808080',
              fontSize: '12px',
              fontWeight: 500,
              justifyContent: 'center'
            }}
          >
            <p style={{ textAlign: 'center' }}>Or Sign In Using</p>
          </div>
          <div className='form__row' style={{
            width: '100%',
            textAlign: 'center'
          }}>
            <Link
              style={{
                borderRadius: '2px'
              }}
              to='/login/slack'
            >
              <img
                style={{
                  height: '32px',
                  margin: 'auto 8px 0 0'
                }}
                src={LoginSlackIcon}
              />
            </Link>
            <Link
              style={{
                color: '#fff',
                fontWeight: 600,
                borderRadius: '2px'
              }}
              to='/login/google'
            >
              <img
                style={{
                  height: '32px',
                  margin: 'auto 8px 0 0'
                }}
                src={LoginGoogleIcon}
              />
            </Link>
          </div>
        </form>
      </div>
    </LandingPage>
    <Snackbar zIndex={9999} type='error' show={errors.status} message={errors.message} />
  </>)
}

export default LoginForm
