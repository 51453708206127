import React, { ChangeEvent } from 'react'
import {
  TransparentButton,
  ButtonWithoutBorder,
  InputForm,
  Loader,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow,
  ButtonsRow,
  FormLabel
} from '../../modules'

interface FormInviteProps {
  currentStep?: number;
  formStep?: number;
  loading: boolean;
  disabled: boolean;
  data: string[];
  change: ((index: number, e: ChangeEvent<HTMLInputElement>) => void);
  remove: ((index: number) => void);
  submit: React.MouseEventHandler<HTMLButtonElement>;
  skip: React.MouseEventHandler<HTMLButtonElement>;
  populate: React.MouseEventHandler<HTMLButtonElement>;
  download: React.MouseEventHandler<HTMLButtonElement>;
}

const Invite: React.FC<FormInviteProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>Welcome to HQ Remote!</h1>
        <p>HQ Remote is going to be a lonely place without some teammates!</p>
        <SignUpFormGroup>
          {props.data.map((_, i) => (
            <SignUpFormRow key={i}>
              <FormLabel>Email Address</FormLabel>
              <InputForm
                type='email'
                placeholder='Email address'
                name='email'
                onChange={(e) => props.change(i, e)}
                value={props.data[i]}
              />
              {i > 0 && (
                <ButtonWithoutBorder
                  type='button'
                  onClick={() => props.remove(i)}
                  className='form__row__remove'>
                  +
                </ButtonWithoutBorder>
              )}
            </SignUpFormRow>
          ))}
          <SignUpFormRow>
            <ButtonWithoutBorder
              type='button'
              className='add-another'
              onClick={props.populate}>
              Add another invite
            </ButtonWithoutBorder>{' '}
          </SignUpFormRow>
          <SignUpFormRow>
            <TransparentButton
              type='button'
              onClick={props.submit}
              inline={true}
              disabled={props.disabled}>
              {props.loading ? <Loader /> : 'Send Invitations'}
            </TransparentButton>
            <ButtonsRow>
              <TransparentButton
                style={{ width: '45%' }}
                type='button'
                inline={true}
                onClick={props.skip}>
                Go to Login
              </TransparentButton>
              <TransparentButton
                style={{ width: '60%' }}
                type='button'
                inline={true}
                onClick={props.download}>
                Download the App
              </TransparentButton>
            </ButtonsRow>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default Invite
