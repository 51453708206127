import React from 'react'
import {
  TransparentButton,
  InputForm,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow,
  SignUpFormRowHalf,
  FormLabel
} from '../../modules'
import { FormFieldProps } from './interface'

interface FormNameProps extends FormFieldProps {
  data: {
    firstName: string;
    lastName: string;
  };
}

const Name: React.FC<FormNameProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>What's your name?</h1>
        <SignUpFormGroup>
          <SignUpFormRow>
            <SignUpFormRowHalf>
              <FormLabel>First Name</FormLabel>
              <InputForm
                type='text'
                placeholder='First Name'
                name='firstName'
                onChange={props.change}
                value={props.data.firstName}
              />
            </SignUpFormRowHalf>
            <SignUpFormRowHalf>
              <FormLabel>Last Name</FormLabel>
              <InputForm
                type='text'
                placeholder='Last Name'
                name='lastName'
                onChange={props.change}
                value={props.data.lastName}
              />
            </SignUpFormRowHalf>
          </SignUpFormRow>
          <SignUpFormRow>
            <TransparentButton
              type='button'
              onClick={props.next}
              disabled={
                props.data.firstName === '' ||
                props.data.lastName === '' ||
                typeof props.data.firstName === 'undefined' ||
                typeof props.data.lastName === 'undefined'
              }>
              Next
            </TransparentButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default Name
