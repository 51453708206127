import React from 'react'
import {
  TransparentButton,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow
} from '../../modules'
import { FormFieldProps } from './interface'

interface FormInvitedProps extends FormFieldProps {
  data: string;
}

const Invited: React.FC<FormInvitedProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>You've been invited to join {props.data}</h1>
        <SignUpFormGroup>
          <SignUpFormRow>
            <TransparentButton type='button' onClick={props.next}>
              Continue
            </TransparentButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default Invited
