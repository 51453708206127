import React from 'react'
import {
  TransparentButton,
  InputForm,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow
} from '../../modules'
import { FormFieldProps } from './interface'

interface TeamNameProps extends FormFieldProps {
  data: string;
}

const TeamName: React.FC<TeamNameProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>Name your HQ Remote team</h1>
        <p>This will be shown in headings and menus.</p>
        <SignUpFormGroup>
          <SignUpFormRow>
            <InputForm
              type='text'
              placeholder='Team Name'
              name='teamName'
              onChange={props.change}
              value={props.data}
            />
          </SignUpFormRow>
          <SignUpFormRow>
            <TransparentButton
              type='button'
              onClick={props.next}
              disabled={
                props.data === '' ||
                typeof props.data === 'undefined'
              }>
              Next
            </TransparentButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default TeamName
