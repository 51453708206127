import React from 'react'
import {
  TransparentButton,
  InputForm,
  SignUpFormContainer,
  SignUpForm,
  SignUpFormGroup,
  SignUpFormRow,
  FormLabel
} from '../../modules'
import { FormFieldProps } from './interface'

interface FormPasswordProps extends FormFieldProps {
  data: string;
}

const Password: React.FC<FormPasswordProps> = (props) => {
  if (props.currentStep !== props.formStep) {
    return null
  }
  return (
    <SignUpFormContainer>
      <SignUpForm>
        <h1>Set yourself a password</h1>
        <SignUpFormGroup>
          <SignUpFormRow>
            <FormLabel>Password</FormLabel>
            <InputForm
              type='password'
              placeholder='Password'
              name='password'
              onChange={props.change}
              value={props.data}
            />
          </SignUpFormRow>
          <SignUpFormRow>
            <TransparentButton
              type='button'
              onClick={props.next}
              disabled={
                props.data === '' ||
                typeof props.data === 'undefined'
              }>
              Next
            </TransparentButton>
          </SignUpFormRow>
        </SignUpFormGroup>
      </SignUpForm>
    </SignUpFormContainer>
  )
}

export default Password
